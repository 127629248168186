import React from "react";
import Container from "../components/container";
import HeroPost from "../components/hero-post";
import Intro from "../components/intro";
// import MoreStories from "../components/more-stories";
// import { graphql } from "gatsby";
// import { getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

export default function Index() {
  // const heroPost = allPosts.nodes[0];
  // const morePosts = allPosts.nodes.slice(1);

  // const image = getImage('https://upload.wikimedia.org/wikipedia/commons/9/9a/Gull_portrait_ca_usa.jpg')
  return (
    <Container>
      <Intro />
      <HeroPost
        // title={"Title"}
        coverImage={"/boda.jpg"}
        date={new Date().toISOString()}
        author={{
          name: "Xabi",
          picture:
            "'https://upload.wikimedia.org/wikipedia/commons/9/9a/Gull_portrait_ca_usa.jpg'",
        }}
        slug={"slug"}
        // excerpt={"lorem ipsum"}
      />

      <div className="bg-indigo-50">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 ">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
            <span className="block">
              Habrán conseguido resolver las pruebas de las tazas?
            </span>
            <span className="block text-indigo-600">Pronto lo sabremos.</span>
          </h2>
          <div className="mt-8 flex">
            <div className="inline-flex rounded-md shadow">
              <Link
                to="/prueba-final"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
              >
                Ir a la prueba final
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={"space-x-1 py-2 flex items-center justify-center"}>
        <Link to="/taza1">
          <button
            type="button"
            className="py-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Taza 1
          </button>
        </Link>

        <Link to="/taza2">
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Taza 2
          </button>
        </Link>
      </div>
      {/*<Link to="/taza1">Taza 1</Link>*/}
      {/*<Link to="/taza2">Taza 2</Link>*/}
      {/*{heroPost && (*/}
      {/*  <HeroPost*/}
      {/*    title={heroPost.title}*/}
      {/*    coverImage={heroPost.coverImage}*/}
      {/*    date={heroPost.date}*/}
      {/*    author={heroPost.author}*/}
      {/*    slug={heroPost.slug}*/}
      {/*    excerpt={heroPost.excerpt}*/}
      {/*  />*/}
      {/*)}*/}
      {/*{morePosts.length > 0 && <MoreStories posts={morePosts} />}*/}
    </Container>
  );
}

// export const query = graphql`
//   {
//     site: datoCmsSite {
//       favicon: faviconMetaTags {
//         ...GatsbyDatoCmsFaviconMetaTags
//       }
//     }
//     blog: datoCmsBlog {
//       seo: seoMetaTags {
//         ...GatsbyDatoCmsSeoMetaTags
//       }
//     }
//     allPosts: allDatoCmsPost(sort: { fields: date, order: DESC }, limit: 20) {
//       nodes {
//         title
//         slug
//         excerpt
//         date
//         coverImage {
//           large: gatsbyImageData(width: 1500)
//           small: gatsbyImageData(width: 760)
//         }
//         author {
//           name
//           picture {
//             gatsbyImageData(
//               layout: FIXED
//               width: 48
//               height: 48
//               imgixParams: { sat: -100 }
//             )
//           }
//         }
//       }
//     }
//   }
// `;
